<template>
  <b-row class="pt-4 pb-4 bottom-border min-height-300 vendor-container">

    <b-col sm="4">
      <b-img fluid :src="logo" class="mobile-padding" />
    </b-col>
    <b-col sm="8" class="mobile-seperator">
      <h2 class="d-none d-lg-block" style="font-size: 20px; font-weight: 600; margin-bottom: 0px;">{{
        vendor.display_name | capitalizeAll
      }}</h2>
      <!-- <a :href="lxPdf" v-show="showDigAdLxPackageLink" target="_BLANK"
        style="font-size: 13px; font-weight: 600; padding-bottom: 10px; text-decoration: none;">
        LX Package Provider
      </a> -->
      <p class="pre-line" style="margin-top: 10px;">{{ vendor.description_1 }}</p>
      <p v-if="showDdcFeeNotice" class="small font-weight-bold">* $149 Monthly Platform Fee</p>
      <p v-if="showDiFeeNotice" class="small font-weight-bold">* $200 Monthly FUEL Platform Fee</p>
      <button @click="launchEnrollment()" type="button" class="btn-acura-primary">
        <b-icon-boxArrowUpRight
          style="width: 22px; height: 23px; margin-bottom: -3px; margin-right: 3px;"></b-icon-boxArrowUpRight>
        ENROLL
      </button>
      <!-- <button @click="playVideo(video)" v-show="vendor.video" type="button" class="btn-acura-secondary desktop-ml-10">
        <b-icon-playFill style="width: 26px; height: 26px; margin-bottom: -6px;"></b-icon-playFill>
        VIDEO
      </button> -->
      <button @click="openPdf()" v-show="vendor.pdf" type="button" class="btn-acura-secondary desktop-ml-10">
        <b-icon-file-earmark style="width: 22px; height: 24px; margin-bottom: -4px;"></b-icon-file-earmark>
        INFO SHEET
      </button>
    </b-col>

  </b-row>
</template>

<script>

export default {

  name: "vendor",

  components: {

  },

  props: {
    vendor: {},
    activeCategory: {},
  },

  data() {
    return {

    }
  },

  methods: {

    openPdf() {
      window.open(this.pdf);
    },

    launchEnrollment() {
      window.scrollTo(0, 0);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleEnrollment");
    },

    playVideo() {
      alert('Video coming soon!');
      // window.scrollTo(0, 0);
      // this.$store.commit("setVideoUrl", this.vendor.video);
      // this.$store.commit("toggleModal");
      // this.$store.commit("toggleVideo");
    },

  },

  computed: {

    logo() {
      return process.env.VUE_APP_FILES_PATH + this.vendor.logo;
    },

    pdf() {
      return process.env.VUE_APP_FILES_PATH + this.vendor.pdf;
    },

    lxPdf() {
      return '/lx_package_one_sheet_rev2.pdf'
    },

    showDdcFeeNotice() {

      if (this.vendor.vendor_id === '130405' && this.activeCategory.category_id === '4649') {
        return true;
      }

      return false;
    },

    showDiFeeNotice() {

      if (this.vendor.vendor_id === '348269' && this.activeCategory.category_id === '4649') {
        return true;
      }

      return false;
    },

    showDigAdLxPackageLink() {
      // if (this.$store.state.lxDigAdVendors.includes(this.vendor.vendor_id) && this.activeCategory.category_id === '4649') {
      //   return true;
      // } else {
      //   return false;
      // }
      return false;
    },

  }
};

</script>

<style scoped>
.min-height-300 {
  min-height: 250px;
}

.bottom-border {
  border-bottom: 1px solid grey;
}

.bottom-border:last-child {
  border-bottom: none;
  margin-bottom: 5px;
}

.vendor-container {
  margin-top: 25px;
}

@media screen and (min-width: 993px) {

  .desktop-ml-10 {
    margin-left: 10px;
  }

}

@media screen and (max-width: 992px) and (min-width: 769px) {

  .btn-acura-primary {
    width: 100%;
  }

  .btn-acura-secondary {
    margin-top: 10px;
    width: 100%;
  }

}

@media screen and (max-width: 768px) {

  .btn-acura-primary {
    width: 100%;
  }

  .btn-acura-secondary {
    margin-top: 10px;
    width: 100%;
  }

  .mobile-width-tweak {
    padding: 0px;
  }

  .mobile-seperator {
    padding-bottom: 25px;
  }

  .mobile-padding {
    padding: 5px;
  }

  .button-outline-light {
    margin-bottom: 0px;
  }

  .vendor-container {
    margin-top: 0px;
  }


}
</style>
